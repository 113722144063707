<template>
  <div >
    <!-- Header -->
    <header class="header">
      <div class="head-top">

        <!-- Menu button -->
        <a href="#" class="menu-btn" v-on:click="toggle_menu">
          <span/>
        </a>

        <!-- Logo -->
        <div class="logo hover-masks-logo">
          <a target="_blank" href="files/cv-jaimedearcos.pdf" download v-on:mouseenter="cursor_over(true)" v-on:mouseleave="cursor_over(false)">
            <span class="mask-lnk">Jaime <strong>de Arcos</strong></span>
            <span class="mask-lnk mask-lnk-hover">Download <strong>CV</strong></span>
          </a>
        </div>

        <!-- Top menu -->
        <div class="top-menu hover-masks">
          <div class="top-menu-nav">
            <div class="menu-topmenu-container">
              <ul class="menu">
                <!-- HOME -->
                <router-link tag="li" to="/home" class="menu-item">
                  <a  v-on:click="transition" v-on:mouseenter="cursor_over(true)" v-on:mouseleave="cursor_over(false)">Home</a>
                </router-link>
                <!-- WORKS -->
                <router-link  tag='li' to="/works" class="menu-item">
                  <a v-on:mouseenter="cursor_over(true)" v-on:mouseleave="cursor_over(false)" v-on:click="transition">Works</a>
                </router-link>
                <!-- BLOG -->
                <li class="menu-item">
                  <a v-on:mouseenter="cursor_over(true)" v-on:mouseleave="cursor_over(false)" v-on:click="transition" :href="blogUrl">Blog</a>
                </li>
                <!-- CONTACTS -->
                <router-link  tag='li' to="/contact" class="menu-item">
                  <a v-on:mouseenter="cursor_over(true)" v-on:mouseleave="cursor_over(false)" v-on:click="transition">Contact</a>
                </router-link>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </header>
  </div>
</template>

<script>
import { bus } from "../main";
import log from "@/services/logger.service";

export default {
  name: 'TopBar',
  data() {
    return {
      opened : true,
      blogEnabled : process.env.VUE_APP_BLOG_ENABLED === "true",
      blogUrl : process.env.VUE_APP_BLOG_URL
    }
  },
  mounted() {
    log.info(`blog enabled is ${this.blogEnabled}`)
  },
  props: {
    msg: String
  },
  methods: {

    transition: function() {
      bus.$emit('transition',null);
      this.menu_close();
    },

    cursor_over: function (data){
      bus.$emit('cursor_over',data);
    },

    menu_open: function() {
      console.log("Opening menu!");
      $('header').addClass('active');
      $('.footer .soc').hide();
      $('body').removeClass('loaded');
      $('body').removeClass('background-enabled');
    },
    menu_close: function (){

      if(!this.opened) {
        console.log("closing menu!");
        $('header').removeClass('active');
        $('.footer .soc').fadeIn();
        $('body').addClass('loaded');
        if ($('.video-bg').length) {
          $('body').addClass('background-enabled');
        }
      }
    },

    toggle_menu:function () {

        console.log("menu clicked");

        if(this.opened){ this.menu_open(); }
        else{            this.menu_close(); }

        this.opened = !this.opened;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
