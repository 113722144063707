import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// External
import jQuery from 'jquery'
import 'bootstrap'

import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
    config: {
        id: 'G-R80JL8TW1N',
        params: {
            send_page_view: false
        }
    }
}, router)


// OWN
import log from "./services/logger.service";

log.init(process.env.VUE_APP_LOG_LEVEL);
window.$ = jQuery;
window._ = require('./assets/libs/grained.js');

// Event bus
export const bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router, store,
  render: h => h(App)
}).$mount('#app');
