const log = {

  levels: {
    TRACE: 1,
    DEBUG: 2,
    INFO:  3,
    WARN:  4,
    ERROR: 5,
    FATAL: 6,
  },

  appLevel: 2,

  init(level) {
    this.appLevel = level;
  },


  getData: function(str){
    return (typeof str === 'undefined')? "":str;
  },

  trace: function(msg,data) {
    if (this.appLevel<= log.levels.TRACE){
      console.log("[TRACE] : " + msg, this.getData(data));
    }
  },
  debug: function(msg,data) {
    if (this.appLevel<= log.levels.DEBUG){
      console.log("[DEBUG]: " + msg,this.getData(data));
    }
  },
  info: function(msg,data) {
    if (this.appLevel<= log.levels.INFO){ console.log("[INFO] : " + msg,this.getData(data)); }
  },
  warn: function(msg,data) {
    if (this.appLevel<= log.levels.WARN){  console.log("[WARN] : " + msg,this.getData(data));   }
  },
  error: function(msg,data) {
    if (this.appLevel<= log.levels.ERROR){ console.log("[ERROR]: " + msg,this.getData(data));  }
    if (this.appLevel<= log.levels.TRACE){ alert(msg); }
  }

};

export default log
