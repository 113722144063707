<template>
  <div>
    <footer class="footer">
      <div class="copy">
        <p>E: Jaime.dearcos@gmail.com</p>
        <p>T: +34 677 892 832</p>
      </div>
      <div class="soc-box">
        <div class="follow-label">Follow Me</div>
        <div class="soc">
          <a target="_blank" href="https://www.linkedin.com/in/jaimedearcos" v-on:mouseenter="cursor_over" v-on:mouseleave="cursor_out">
            <span class="icon fab fa-linkedin"/>
          </a>
          <a target="_blank" href="https://twitter.com/jaimedearcos" v-on:mouseenter="cursor_over" v-on:mouseleave="cursor_out">
            <span class="icon fab fa-twitter"/>
          </a>
          <a target="_blank" href="https://github.com/JaimeDeArcos/" v-on:mouseenter="cursor_over" v-on:mouseleave="cursor_out">
            <span class="icon fab fa-github"/>
          </a>
        </div>
      </div>
      <div class="clear"/>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ContactsFooter',
  props: {
    msg: String
  },
  mounted() {

    /* On Scroll  */
    $(window).on('scroll', function () {

      /* add/remove background-enabled class */
      if ($(this).scrollTop() >= $('.section.started').height()) {
        $('body').removeClass('background-enabled');
      } else {
        if ((!$('header').hasClass('active')) && $('.video-bg').length) {
          $('body').addClass('background-enabled');
        }
      }

      /* add/remove header/footer fixed class */
      if (($(this).scrollTop() >= 100) && ($('.section').length > 1)) {
        $('.header').addClass('fixed');
        $('.footer').addClass('fixed');
        $('.mouse_btn').fadeOut();
      }
      if (($(this).scrollTop() <= 100) && ($('.section').length > 1)) {
        $('.header').removeClass('fixed');
        $('.footer').removeClass('fixed');
        $('.mouse_btn').fadeIn();
      }

    });


  },
  methods: {

    cursor_over: function (){
      $(".cursor-follower").stop().animate({width: 86, height: 86, opacity: 0.1, margin: '-43px 0 0 -43px'}, 500);
    },

    cursor_out: function (){
      $(".cursor-follower").stop().animate({width: 26, height: 26, opacity: 0.4, margin: '-13px 0 0 -13px'}, 500);
    }
  },
}
</script>

<style scoped lang="scss">

</style>
