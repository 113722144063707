<template>

  <div>
      <!-- Section Started -->
      <div class="section started personal" id="section-started">

        <!-- background -->
        <div class="video-bg">
          <div class="video-bg-mask"></div>
          <div class="video-bg-texture" id="grained_container"></div>
        </div>

          <!-- started content -->
          <div class="centrize full-width">
              <div class="vertical-center">
                  <div class="started-content">
                      <h1 class=" title-404 ">
                          404
                      </h1>
                      <div class="h-subtitle typing-subtitle">
                          <p>OH, OH, This page does  <strong>not exist</strong></p>
                      </div>
                      <span class="typed-subtitle"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Typed from 'typed.js';
import TopBar from "../components/TopBar";
import ContactsFooter from "../components/Footer";


export default {
  name: 'home',
  components: {},
  methods: {

  },
  mounted() {

    /* Grained background */
    let grained_options = {
      'animate': true,
      'patternWidth': 400,
      'patternHeight': 400,
      'grainOpacity': 0.15,
      'grainDensity': 3,
      'grainWidth': 1,
      'grainHeight': 1
    };
    grained('#grained_container', grained_options);

      let options = {
          stringsElement: '.typing-subtitle',
          loop: true,
          typeSpeed: 40
      };
      new Typed('.typed-subtitle', options);

      let options_bread = {
          stringsElement: '.typing-bread',
          typeSpeed: 40
      };

      setTimeout(function(){
          new Typed('.typed-bread', options_bread);
      }, 1000);

  }
}
</script>

<style>
    .title-404{
        font-size: 400px;
        color: #222;
    }
</style>