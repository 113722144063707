<template>

    <div>

        <detail-header title="Works"/>

        <!-- Works -->
        <div class="section works" id="section-portfolio">
            <div class="content">

                <!-- portfolio filter -->
                <div class="filter-menu content-box">
                    <div class="filters">
                        <div class="btn-group">
                            <label data-text="All" class="glitch-effect"><input type="radio" name="fl_radio" value=".box-item">All</label>
                        </div>
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Video"><input type="radio" name="fl_radio" value=".f-2020" />2020</label>-->
                        <!--                </div>-->
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Music"><input type="radio" name="fl_radio" value=".f-2019" />2019</label>-->
                        <!--                </div>-->
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Links"><input type="radio" name="fl_radio" value=".f-2018" />2018</label>-->
                        <!--                </div>-->
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Image"><input type="radio" name="fl_radio" value=".f-2017" />2017</label>-->
                        <!--                </div>-->
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Gallery"><input type="radio" name="fl_radio" value=".f-2016" />2016</label>-->
                        <!--                </div>-->
                        <!--                <div class="btn-group">-->
                        <!--                  <label data-text="Gallery"><input type="radio" name="fl_radio" value=".f-2016" />2015</label>-->
                        <!--                </div>-->
                    </div>
                </div>

                <!-- portfolio items -->
                <div class="box-items portfolio-items">

                    <!-- INDITEX -->
                    <work-item img="img/works/inditex.jpg"
                               category="Solutions Architect"
                               company="Inditex"
                               link="/works/inditex" />

                    <!-- Twilio -->
                    <work-item img="img/works/twilio.jpg"
                               category="Software Engineer"
                               company="Twilio Inc."
                               link="/works/twilio" />

                    <!-- EL CORTE INGLÉS -->
                    <work-item img="img/works/el-corte-ingles.jpg"
                               category="Software Engineer"
                               company="El Corte inglés"
                               link="/works/el-corte-ingles" />

                        <!-- STRATIO -->
                    <work-item img="img/works/stratio.jpg"
                               category="Software Engineer"
                               company="Stratio Big Data"
                               link="/works/stratio" />

                    <!-- ZONA-APP -->
                    <work-item img="img/works/eurostar-mediagroup.jpg"
                               category="Software Engineer"
                               company="Eurostar Mediagroup"
                               link="/works/eurostar-mediagroup" />

                    <!-- PROFILE -->
                    <work-item img="img/works/profile.png"
                               category="Software Engineer"
                               company="Profile"
                               link="/works/profile" />

                    <!-- SATEC -->
                    <work-item img="img/works/satec.jpg"
                               category="Software Engineer"
                               company="Satec"
                               link="/works/satec" />

                </div>

                <div class="clear"/>

            </div>
        </div>

    </div>

</template>

<script>

    import DetailHeader from "../components/DetailHeader";
    import WorkItem from "../components/WorkItem";

    export default {
        name: 'works',
        components: { DetailHeader,WorkItem },
        data() {
            return {
                typing: false
            }
        },
        methods: {

            hover_mouse_enter: function (e) {

                let that = e.target;

                if ($(that).find(".ink").length === 0) {
                    $(that).prepend("<span class='ink'></span>");
                }
                var ink = $(that).find(".ink");
                ink.removeClass("animate");
                if (!ink.height() && !ink.width()) {
                    var d = Math.max($(that).outerWidth(), $(that).outerHeight());
                    ink.css({ height: d,  width: d });
                }
                var x = e.pageX - $(that).offset().left - ink.width() / 2;
                var y = e.pageY - $(that).offset().top - ink.height() / 2;
                ink.css({ top: y + 'px', left: x + 'px' }).addClass("ink-animate");
                $('.cursor-follower').addClass('hide');
            },
            hover_mouse_leave: function (e) {
                let that = e.target;
                var ink = $(that).find(".ink");
                var x = e.pageX - $(that).offset().left - ink.width() / 2;
                var y = e.pageY - $(that).offset().top - ink.height() / 2;
                ink.css({ top: y + 'px', left: x + 'px' }).removeClass("ink-animate");
                $('.cursor-follower').removeClass('hide');
            }

        },
        mounted() {

        }
    }
</script>
<style lang="scss">
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
</style>