<template>

    <div>

        <!-- Header -->
        <detail-header title="Contact"/>

        <!-- Works -->
        <div class="section works" id="section-portfolio">
            <div class="content">

                <!-- title -->
                <div class="title">
                    <div class="title_inner">Contacts Info</div>
                </div>

                <!-- contacts items -->
                <div class="service-items">

                    <!--                  <div class="service-col">-->
                    <!--                      <div class="service-item content-box">-->
                    <!--                          <div class="icon"><span class="fas fa-phone"></span></div>-->
                    <!--                          <div class="name">Phone</div>-->
                    <!--                          <div class="text">+ (34) 677 677 677</div>-->
                    <!--                      </div>-->
                    <!--                  </div>-->

                    <div class="service-col">
                        <div class="service-item content-box">
                            <div class="icon"><span class="fas fa-envelope"></span></div>
                            <div class="name">Email</div>
                            <div class="text"><a href="mailto:jaime.dearcos@gmail.com">jaime.dearcos@gmail.com</a>
                            </div>
                        </div>
                    </div>

                    <div class="service-col">
                        <div class="service-item content-box">
                            <div class="icon"><span class="fas fa-map-marker-alt"></span></div>
                            <div class="name">Address</div>
                            <div class="text">Madrid, Spain</div>
                        </div>
                    </div>

                    <!--                  <div class="service-col">-->
                    <!--                      <div class="service-item content-box">-->
                    <!--                          <div class="icon"><span class="fas fa-user-tie"></span></div>-->
                    <!--                          <div class="name">Freelance Available</div>-->
                    <!--                          <div class="text">I am available for Freelance hire</div>-->
                    <!--                      </div>-->
                    <!--                  </div>-->

                </div>


                <div class="clear"/>

            </div>
        </div>

        <div class="section contacts" >

        </div>


        <!-- Section Contacts Form -->
<!--        <div class="section contacts" id="section-contacts">-->
<!--            <div class="content">-->

<!--                &lt;!&ndash; title &ndash;&gt;-->
<!--                <div class="title">-->
<!--                    <div class="title_inner">Contacts Form</div>-->
<!--                </div>-->

<!--                &lt;!&ndash; form &ndash;&gt;-->
<!--                <div class="contact_form content-box">-->
<!--                    <form id="cform" v-on:submit.prevent>-->
<!--                        <div class="group-val">-->
<!--                            <input type="text" name="name" placeholder="Name"/>-->
<!--                        </div>-->
<!--                        <div class="group-val">-->
<!--                            <input type="email" name="email" placeholder="Email"/>-->
<!--                        </div>-->
<!--                        <div class="group-val ct-gr">-->
<!--                            <textarea name="message" placeholder="Message"></textarea>-->
<!--                        </div>-->
<!--                        <div class="group-bts">-->
<!--                            <button v-on:click.prevent="alert('Thanks, your message is sent successfully.')"-->
<!--                                    class="btn hover-animated">-->
<!--                                <span class="circle"></span>-->
<!--                                <span class="lnk">Send Message</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                    <div class="alert-success">-->
<!--                        <p>Thanks, your message is sent successfully.</p>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="clear"></div>-->
<!--        </div>-->

    </div>

</template>

<script>

    import Typed from 'typed.js';
    import DetailHeader from "../components/DetailHeader";

    export default {
        name: 'home',
        components: {DetailHeader},
        data() {
            return {
                typing: false
            }
        },
        methods: {

            cursor_over: function () {
                $(".cursor-follower").stop().animate({
                    width: 86,
                    height: 86,
                    opacity: 0.1,
                    margin: '-43px 0 0 -43px'
                }, 500);
            },

            cursor_out: function () {
                $(".cursor-follower").stop().animate({
                    width: 26,
                    height: 26,
                    opacity: 0.4,
                    margin: '-13px 0 0 -13px'
                }, 500);
            }

        },
        mounted() {

            /* Grained background */
            let grained_options = {
                'animate': true,
                'patternWidth': 400,
                'patternHeight': 400,
                'grainOpacity': 0.15,
                'grainDensity': 3,
                'grainWidth': 1,
                'grainHeight': 1
            };
            grained('#grained_container', grained_options);

            if (this.typing) {
                let options = {stringsElement: '.typing-subtitle', loop: true, typeSpeed: 40};
                new Typed('.typed-subtitle', options);
            }

            /*
                Hover Masks
            */
            $('.hover-masks a').each(function () {
                var mask_val = $(this).html();
                $(this).wrapInner('<span class="mask-lnk"></span>');
                $(this).append('<span class="mask-lnk mask-lnk-hover">' + mask_val + '</span>');
            });

            /* Hover Button Effect */
            $('.hover-animated .circle').on({
                mouseenter: function (e) {
                    if ($(this).find(".ink").length === 0) {
                        $(this).prepend("<span class='ink'></span>");
                    }
                    var ink = $(this).find(".ink");
                    ink.removeClass("animate");
                    if (!ink.height() && !ink.width()) {
                        var d = Math.max($(this).outerWidth(), $(this).outerHeight());
                        ink.css({
                            height: d,
                            width: d
                        });
                    }
                    var x = e.pageX - $(this).offset().left - ink.width() / 2;
                    var y = e.pageY - $(this).offset().top - ink.height() / 2;
                    ink.css({
                        top: y + 'px',
                        left: x + 'px'
                    }).addClass("ink-animate");
                    $('.cursor-follower').addClass('hide');
                },
                mouseleave: function (e) {
                    var ink = $(this).find(".ink");
                    var x = e.pageX - $(this).offset().left - ink.width() / 2;
                    var y = e.pageY - $(this).offset().top - ink.height() / 2;
                    ink.css({
                        top: y + 'px',
                        left: x + 'px'
                    }).removeClass("ink-animate");
                    $('.cursor-follower').removeClass('hide');
                }
            });


        }
    }
</script>

<style>
    .blogShort {
        border-bottom: 1px solid #ddd;
    }

    .add {
        background: #333;
        padding: 10%;
        height: 300px;
    }

    .nav-sidebar {
        width: 100%;
        padding: 8px 0;
        border-right: 1px solid #ddd;
    }

    .nav-sidebar a {
        color: #333;
        -webkit-transition: all 0.08s linear;
        -moz-transition: all 0.08s linear;
        -o-transition: all 0.08s linear;
        transition: all 0.08s linear;
    }

    .nav-sidebar .active a {
        cursor: default;
        background-color: #34ca78;
        color: #fff;
    }

    .nav-sidebar .active a:hover {
        background-color: #37D980;
    }

    .nav-sidebar .text-overflow a,
    .nav-sidebar .text-overflow .media-body {
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .btn-blog {
        color: #ffffff;
        background-color: #37d980;
        border-color: #37d980;
        border-radius: 0;
        margin-bottom: 10px
    }

    .btn-blog:hover,
    .btn-blog:focus,
    .btn-blog:active,
    .btn-blog.active,
    .open .dropdown-toggle.btn-blog {
        color: white;
        background-color: #34ca78;
        border-color: #34ca78;
    }

    h2 {
        color: #34ca78;
    }

    .margin10 {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .footer{
        padding: 100px;
        margin-top:100px
    }

</style>
