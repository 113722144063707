<template>

    <div>

        <detail-header :title="works[id].title"/>

        <!-- Work Single -->
        <div class="section blog">
            <div class="content content-box">
                <div class="single-post-text">

                    <!-- portfolio content -->
                    <div class="portfolio-info portfolio-cols">
                        <div class="description-col">

                            <!-- title -->
                            <div class="title">
                                <div class="title_inner">Description</div>
                            </div>

                            <!-- text -->
                            <div class="single-post-text">

                                <p v-for="param in works[id].paragraphs">{{param}} </p>

                                <strong>Technology Stack</strong>

                                <ul>
                                    <li  v-for="tech in works[id].stack" >{{tech}}</li>
                                </ul>

                            </div>

                        </div>
                        <div class="details-col">

                            <!-- title -->
                            <div class="title">
                                <div class="title_inner">Details</div>
                            </div>

                            <!-- details -->
                            <ul class="details-list">
                                <li><strong>FROM:</strong> {{works[id].from}} </li>
                                <li><strong>TO:</strong>{{works[id].to}}</li>
                            </ul>

                        </div>
                    </div>

                </div>
                <div class="clear"></div>
            </div>
        </div>


    </div>

</template>

<script>

    import DetailHeader from "../components/DetailHeader";
    import works from '../data/works.json';

    export default {
        name: 'work-detail',
        components: {DetailHeader},
        data() {
            return {
                id: this.$route.params.Pid,
                works: works
            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>
