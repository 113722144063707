
<template>
  <div id="app" v-on:mousemove="onMouseMove($event)">

    <!-- Cursor -->
    <div class="cursor-follower"
         :style="'transform: translate3d('+this.cursor.x+'px,'+this.cursor.y+'px,0px)'"
         v-if="this.showCursor"/>

    <!-- Container -->
    <div class="container">

      <preloader/>



      <!-- TOP - BAR-->
      <top-bar/>

      <!-- Content -->
      <div class="wrapper">
        <transition name="fade">
            <router-view/>
        </transition>
      </div>

      <!-- Footer -->
      <contacts-footer/>

    </div>

  </div>

</template>

<script>

  import ContactsFooter from "./components/Footer";
  import TopBar from "./components/TopBar";
  import { bus } from './main';
  import Preloader from "./components/Preloader";


  export default {
    name: "app",
    components: {Preloader, ContactsFooter, TopBar },
    data() {
      return {
        showCursor: true,
        cursor: { x:0 , y:0 }
      }
    },
    created() {
      bus.$on('cursor_over', (data) => {
        if(data){
          $(".cursor-follower").stop().animate({width: 86, height: 86, opacity: 0.1, margin: '-43px 0 0 -43px'}, 500);
        } else{
          $(".cursor-follower").stop().animate({width: 26, height: 26, opacity: 0.4, margin: '-13px 0 0 -13px'}, 500);
        }
      })
    },

    methods: {

      onMouseMove(e) {
        this.cursor.x = e.pageX;
        this.cursor.y = e.pageY;
      },

    },
    mounted() {  }

  };
</script>

<style lang="scss">

  #app{
    min-height: 100vh;
  }

  @import "assets/scss/basic";
  @import "assets/scss/layout";

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }




  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
