import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Work from '../views/Work.vue'
import Contact from '../views/Contacts.vue'
import NotFound from '../views/NotFound.vue'
import WorkDetail from "../views/WorkDetail.vue";

Vue.use(VueRouter);

const routes = [

  { path: '/',           redirect: '/home' },
  { path: '/home',       name: 'home',       component: Home ,      meta: { breadcrumb: [{name:'Home'}]} },
  { path: '/works',      name: 'works',      component: Work ,      meta: { breadcrumb: [{name:'Home',link:'home'}, {name:'Works'}]}},
  { path: '/works/:Pid', name: 'work-detail',component: WorkDetail, meta: { breadcrumb: [{name:'Home',link:'home'}, {name:'Works',link:'works'}, {name: 'Pid'}], logo:'Pid'}},
  { path: '/contact',    name: 'contact',    component: Contact ,   meta: { breadcrumb: [{name:'Home',link:'home'}, {name:'Contact'}]}},
  { path: '/not-found',  name: 'notFound',   component: NotFound,   meta: { breadcrumb: [{name:'not-found'}]}},
  { path: '*',           redirect: '/not-found' }
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "current-menu-item",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router
