<template>
  <div>

    <!-- Preloader -->
    <div class="preloader">
      <div class="centrize full-width">
        <div class="vertical-center">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Lines -->
    <div class="lines">
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
      <div class="line-col"></div>
    </div>

  </div>
</template>

<script>
import {bus} from "../main";

export default {
  name: 'Preloader',
  props: { },
  mounted() {
   this.doit();
   this.animeLines();
  },

  created(){

  },
  methods: {

    clearScreen() {
      $('.lines').removeClass('finish');
      $('.lines').removeClass('ready');
    },

    doit() {
      let preload = $('.preloader');
      preload.find('.spinner').fadeOut(function(){ preload.fadeOut(); });
    },
    animeLines() {

      $('.lines').addClass('finish');

      setTimeout(function(){
         $('.lines').addClass('ready');
      }, 2000);
    }
  },
}
</script>

<style scoped lang="scss">

</style>
