<template>

    <div>

        <!-- Section Started -->
        <div id="section-started" class="section started layout-creative ">

            <div class="logo-background-wrapper">
                <img class="logo-background" :src="logo">
            </div>

            <!-- background -->
            <div class="video-bg">
                <div class="video-bg-mask"></div>
                <div class="video-bg-texture" id="grained_container"/>
            </div>

            <!-- started content -->
            <div class="centrize full-width">
                <div class="vertical-center">
                    <div class="started-content">

                        <h1 class="h-title">{{title}}</h1>
                        <div class="h-subtitles">
                            <div class="h-subtitle typing-bread">
                            </div>
                            <span class="typed-bread">
                                <p class="breadcrumbs">
                                    <template v-for="(breadcrumb,idx) in breadcrumbList">
                                        <router-link v-if="breadcrumb.link!==undefined" tag='a' :to="'/'+breadcrumb.link">
                                            {{breadcrumb.name}} /
                                        </router-link>

                                        <template v-else >
                                            <template v-if="breadcrumb.name==='Pid'">
                                                {{title}}
                                            </template>
                                            <template v-else > {{breadcrumb.name}} </template>
                                        </template>

                                    </template>
                                </p>

                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

    export default {
        name: 'detail-header',
        components: {},
        props: ['title'],
        beforeCreate() {
            this.breadcrumbList = this.$route.meta.breadcrumb;
            this.logo = this.$route.meta.logo;
            let filename = this.logo + '.png'
             if(this.logo==='Pid'){
                filename = this.$route.params.Pid + '.png'

            }else if(this.logo==null){
                 return;
            }
            this.logo = './img/logo/$filename'.replace("$filename", filename);
        },
        data() {
            return { }
        },
        methods: {},
        mounted() {

            this.breadcrumbList = this.$route.meta.breadcrumb;

            let grained_options = {
                'animate': true,
                'patternWidth': 400,
                'patternHeight': 400,
                'grainOpacity': 0.15,
                'grainDensity': 3,
                'grainWidth': 1,
                'grainHeight': 1
            };
            grained('#grained_container', grained_options);
        }
    }
</script>
<style lang="scss">

    .logo-background{
        content: "";
        opacity: 0.2;
        filter: alpha(opacity=20);
        position: absolute;
        width: 50%;
        height: auto;
        /*bottom: 5%;*/
        top: 25%;
        /* transform: rotate(-30deg); */
        left: 25%;
    }

    /* section started desktop */
    @media (min-width: 1200px) { }

    /* section started tablet */
    @media (max-width: 1199px) {
        .logo-background{
            width: 100%;
            height: auto;
            bottom: 5%;
            left: 5%;
        }
    }

    /* section started mobile */
    @media (max-width: 720px) {
        .logo-background{
            width: 100%;
            height: auto;
            bottom: 5%;
            left: 5%;
        }
    }

</style>