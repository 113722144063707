<template>

  <div id="home-section">

      <!-- Section Started -->
      <div class="section started personal" id="section-started">

        <!-- background -->
        <div class="video-bg">
          <div class="video-bg-mask"></div>
          <div class="video-bg-texture" id="grained_container"></div>
        </div>

        <!-- started content -->
        <div class="centrize full-width">
          <div class="vertical-center">
            <div class="started-content">
              <div class="logo" style="background-image: url(img/man.jpg);"></div>
              <h1 class="h-title">
                Hello, I’m <strong>Jaime de Arcos</strong>, Software Engineer <br /> and Microservices Developer based in Madrid.
              </h1>

              <div class="h-subtitle typing-subtitle">
                <p>I love <strong>coding</strong></p>
                <p>I develop <strong>microservices</strong></p>
              </div>

              <span class="typed-subtitle"/>

            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>

import Typed from 'typed.js';
import { bus } from '../main'

export default {
  name: 'home',
  data() {
    return {
      typed : { }
    }
  },
  methods: {

    cursor_over: function (){
      bus.$emit('cursor_over',true);
    },

    cursor_out: function (){
      bus.$emit('cursor_over',true);
    }

  },
  mounted() {

    /* Grained background */
    let grained_options = {
      'animate': true, 'patternWidth': 400, 'patternHeight': 400, 'grainOpacity': 0.15,  'grainDensity': 3, 'grainWidth': 1.5, 'grainHeight': 1.5
    };
    grained('#grained_container', grained_options);

    let options = { stringsElement: '.typing-subtitle', loop: true, typeSpeed: 40 };
    this.typed = new Typed('.typed-subtitle', options);
  }
}
</script>

<style scoped lang="scss">

</style>
