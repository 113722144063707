<template>

        <transition name="fade">
            <div class="box-item f-2020 f-2019" v-if="loaded">
                <div class="image">
                    <router-link  tag='a' :to="link"  class="hover-animated">
                        <img :src="img" class="wp-post-image" alt=""/>
                        <span class="info circle"
                          v-on:mouseenter="hover_mouse_enter"
                          v-on:mouseleave="hover_mouse_leave">
                            <span class="centrize full-width">
                                <span class="vertical-center">
                                    <span class="desc">
                                        <span class="category">{{category}}</span>
                                        <span class="name">{{company}}</span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </router-link>
                </div>
            </div>
        </transition>

</template>

<script>

    export default {
        name: 'work-item',
        components: {  },
        props: ["category","company","img","link"],
        data() {
            return {
                loaded: false
            }
        },
        mounted() {
            this.loaded = true;
        },
        methods: {

            hover_mouse_enter: function (e) {

                let that = e.target;

                if ($(that).find(".ink").length === 0) {
                    $(that).prepend("<span class='ink'></span>");
                }
                var ink = $(that).find(".ink");
                ink.removeClass("animate");
                if (!ink.height() && !ink.width()) {
                    var d = Math.max($(that).outerWidth(), $(that).outerHeight());
                    ink.css({ height: d,  width: d });
                }
                var x = e.pageX - $(that).offset().left - ink.width() / 2;
                var y = e.pageY - $(that).offset().top - ink.height() / 2;
                ink.css({ top: y + 'px', left: x + 'px' }).addClass("ink-animate");
                $('.cursor-follower').addClass('hide');
            },
            hover_mouse_leave: function (e) {
                let that = e.target;
                var ink = $(that).find(".ink");
                var x = e.pageX - $(that).offset().left - ink.width() / 2;
                var y = e.pageY - $(that).offset().top - ink.height() / 2;
                ink.css({ top: y + 'px', left: x + 'px' }).removeClass("ink-animate");
                $('.cursor-follower').removeClass('hide');
            }

        }
    }
</script>
<style lang="scss" scoped>

    .fade-enter-active {
        transition: opacity 5s ease-in-out;
    }

    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter {
        opacity: 0;
    }
</style>
